<template>
  <div id="smartai_base_page" style="margin: 20px" v-cloak>
    <!--基础组件的添加 start--->
    <el-dialog
      title="设置评分点"
      :visible.sync="dialogBaseVisible"
      width="1000px"
      top="5vh"
    >
      <div style="padding: 22px 30px 0 0; border: 1px solid #ddd">
        <div
          class="add-sub-item"
          v-for="(item, index) in answerData"
          :key="index"
        >
          <div class="add-sub-item-ctt">
            <span class="required">评分点{{ index + 1 }}：</span>
            <el-select
              size="mini"
              style="width: 150px"
              v-model="item.base_id"
              @change="handleAnswerKeyChange(item, $event)"
            >
              <el-option
                v-for="(base_item, bese_index) in base_component_list"
                :key="bese_index"
                :label="base_item.desc"
                :value="String(base_item.id)"
              ></el-option>
            </el-select>
            <div class="add-sub-item-ans">
              <div
                v-for="(detail_item, detail_index) in item.point.field_json"
                :key="detail_index"
              >
                <div v-if="detail_item.base_name == 'range_score'">
                  <el-select
                    size="mini"
                    style="width: 120px"
                    v-model="detail_item.params.is_type"
                  >
                    <el-option value="0" label="答案长度">答案长度</el-option>
                    <el-option value="1" label="答案本身">答案本身</el-option>
                  </el-select>
                </div>
              </div>
            </div>
          </div>
          <div class="add-sub-item-btns">
            <el-button
              size="mini"
              circle
              type="danger"
              icon="el-icon-minus"
              v-show="answerData.length > 1"
              @click="delPoint(index)"
            ></el-button>
            <el-button
              size="mini"
              circle
              type="success"
              icon="el-icon-plus"
              v-if="index === answerData.length - 1"
              @click="addPoint"
            ></el-button>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogBaseVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveRatingByBase">确 定</el-button>
      </div>
    </el-dialog>
    <!--基础组件的添加 end--->

    <!--评分组件的添加 start--->
    <el-dialog :title="dialogTitle" :visible.sync="dialogFormVisible">
      <el-form label-width="100px" :model="item_form" size="small">
        <el-form-item label="组件名称" prop="name">
          <el-input v-model="item_form.name"></el-input>
        </el-form-item>
        <el-form-item label="组件标识" prop="sign">
          <el-input
            v-model="item_form.sign"
            placeholder="组件唯一标识"
          ></el-input>
        </el-form-item>
        <el-form-item label="评分项标识">
          <el-input
            v-model="item_form.select_sign"
            placeholder="评分项的标识"
          ></el-input>
        </el-form-item>
        <el-form-item label="统计字段">
          <el-input
            v-model="item_form.user_statistics_field"
            placeholder="统计字段"
          ></el-input>
        </el-form-item>
        <el-form-item label="评分点设置">
          <el-link
            type="success"
            :underline="false"
            style="font-weight: 900"
            @click="openSetBaseComponents"
            >点击设置评分点</el-link
          >
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeRatingDialog">取 消</el-button>
        <el-button type="primary" @click="saveRating">确 定</el-button>
      </div>
    </el-dialog>
    <!--评分组件的添加 end--->
    <div id="toolbar">
      <el-button size="medium" type="primary" @click="addRating"
        >新增组件</el-button
      >
    </div>
    <div id="table" style="margin-top: 10px">
      <el-table :data="ratingTableData" style="width: 100%; padding: 10px">
        <el-table-column prop="id" label="编号" width="60"> </el-table-column>
        <el-table-column prop="name" label="组件名称"> </el-table-column>
        <el-table-column prop="sign" label="组件标识"> </el-table-column>
        <el-table-column prop="select_sign" label="评分项标识">
        </el-table-column>
        <el-table-column label="操作" width="200">
          <template slot-scope="scope">
            <el-button size="mini" @click="handleEditRating(scope.row)"
              >编辑</el-button
            >
            <el-button
              size="mini"
              type="danger"
              @click="handleDelRating(scope.row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        style="margin-top: 10px"
        background
        @current-change="changePagetoItem"
        :page-size="item_pagesize"
        :total="item_totalcount"
        layout="prev, pager, next"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import {
  getBaseList,
  getRatingList,
  saveRatings,
  delRating,
} from "@/api/admin.js";
export default {
  data() {
    return {
      dialogTitle: "新增组件",
      dialogFormVisible: false,
      dialogBaseVisible: false,
      formLabelWidth: "120px",
      answerData: [
        {
          base_id: "",
          point: [],
        },
      ],
      answerTemplete: {
        base_id: "",
        point: [],
      },
      base_component_list: [],
      item_form: {
        id: 0,
        name: "",
        sign: "",
        select_sign: "",
        user_statistics_field: "",
        base_point: [],
      },
      ratingTableData: [],
      item_page: 1,
      item_pagesize: 10,
      item_totalcount: 0,
    };
  },
  methods: {
    handleDelRating: function (id) {
      let vm = this;
      vm.$confirm("确认要删除基础组件?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delRating({
            delete_id: id,
          }).then((res) => {
            if (res.code == 1) {
              vm.$message({
                type: "success",
                message: "删除成功",
              });
              vm.getRatingList();
            }
          });
        })
        .catch(() => {
          vm.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    handleEditRating: function (arr) {
      this.dialogTitle = "编辑组件";
      this.answerData = [
        {
          base_id: "",
          point: [],
        },
      ];
      this.dialogFormVisible = true;
      this.item_form = JSON.parse(JSON.stringify(arr));
    },
    closeRatingDialog: function () {
      this.dialogFormVisible = false;
    },
    delPoint: function (index) {
      this.answerData.splice(index, 1);
    },
    addPoint: function () {
      this.answerData.push(JSON.parse(JSON.stringify(this.answerTemplete)));
    },
    handleAnswerKeyChange: function (obj, keyval) {

      // 显示出相应的评分点格式
      this.base_component_list.forEach((element) => {
        if (element.id == keyval) {
          obj.point = element;
        }
      });
    },
    getRatingList: function () {
      let vm = this;
      getRatingList({
        page: vm.item_page,
        page_size: vm.item_pagesize,
        is_page: 1,
      }).then((res) => {
        if (res.code == 1) {
          vm.ratingTableData = res.data.data;
          vm.item_totalcount = res.data.total;
        }
      });
    },
    // 组件分页切换
    changePagetoItem: function (page) {
      this.item_page = page;
      this.getRatingList();
    },
    addRating: function () {
      this.dialogTitle = "新增组件";
      (this.item_form = {
        id: 0,
        name: "",
        sign: "",
        select_sign: "",
        user_statistics_field: "",
        base_point: [],
      }),
        // 还原评分点的数据
        (this.answerData = [
          {
            base_id: "",
            point: [],
          },
        ]);
      this.dialogFormVisible = true;
    },
    saveRating: function () {
      let vm = this;
      saveRatings({ ...vm.item_form }).then((res) => {
        if (res.code == 1) {
          vm.closeRatingDialog();
          vm.getRatingList();
        }
      });
    },
    openSetBaseComponents: function () {
      if (this.item_form.base_point.length > 0) {
        this.answerData = this.item_form.base_point;
      }
      this.dialogBaseVisible = true;
    },
    saveRatingByBase: function () {
      let vm = this;
      vm.item_form.base_point = [];
      this.answerData.forEach((element) => {
        if (element.base_id != "") {
          vm.item_form.base_point.push(element);
        }
      });
      this.dialogBaseVisible = false;
    },
    // 获取所有的基础组件
    getBaseList: function () {
      let vm = this;
      getBaseList().then((res) => {
        if (res.code == 1) {
          vm.base_component_list = res.data;
        }
      });

      //   $.ajax({
      //     type: "get",
      //     url: "index.php?act=score_smartai&op=getbaselist",
      //     dataType: "json",
      //     success: function (res) {
      //       if (res.code == 200) {
      //         vm.base_component_list = res.data;
      //       }
      //     },
      //   });
    },
  },

  created: function () {
    this.getBaseList();
    this.getRatingList();
  },
};
</script>
<style lang="less" scoped>
.add-sub-item {
  margin: 0 0 22px 15px;
  border-radius: 5px;
}
.add-sub-item-ctt,
.add-sub-item-btns {
  display: inline-block;
  vertical-align: top;
}
.add-sub-item-ctt {
  padding: 5px 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  width: 85%;
}
.add-sub-item-ctt span {
  margin-left: 10px;
}
.add-sub-item-ans {
  display: inline-block;
  width: 320px;
}
.add-sub-item-btns {
  margin: 5px 0 0 10px;
}
[v-cloak] {
  display: none !important;
}
</style>